import { DefaultTheme } from 'styled-components'
import {
  gray05,
  gray60,
  gray80,
  gray100,
  primary05,
  primary100,
  white,
} from './colors'

export const darkTheme: DefaultTheme = {
  background: gray100,
  dividerColor: gray60,
  headerTextColor: gray05,
  isDarkMode: true,
  textPrimaryColor: gray05,
}

export const lightTheme: DefaultTheme = {
  background: white,
  dividerColor: primary05,
  headerTextColor: primary100,
  isDarkMode: false,
  textPrimaryColor: gray80,
}
