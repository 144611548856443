import { ReactComponent as Logo } from '../../images/TellyLogo.svg'
import { useMount } from 'react-use'
import { parse } from 'query-string'
import { useState, useRef } from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title,
  LogoContainer,
  Main,
  Message,
  Text,
  RightBackground,
  Footer,
  GoToTellyApp,
} from './ResetPassword.styles'
import { getSetPasswordMobileURI } from 'constants/mobile'

export const ResetPassword: React.FC = (): JSX.Element => {
  const [code, setCode] = useState('')
  const anchorRef = useRef<HTMLAnchorElement>(null)

  useMount(() => {
    const { code } = parse(location.search)
    if (code) {
      setCode(code as string)
      //Trigger click programatically
      setTimeout(() => {
        if (anchorRef.current) {
          anchorRef?.current?.click()
        }
      }, 200)
    }
  })

  return (
    <Wrapper>
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>

      <Main>
        <Container>
          <Title>Reset password</Title>
          <Message>Reset your password on Telly Mobile App</Message>
          <Text>
            You’ll automatically be redirected to the Telly Mobile app to reset
            your password. If you aren’t automatically redirected, please click
            on the “Reset Password” link below.
          </Text>
          {code && (
            <GoToTellyApp ref={anchorRef} href={getSetPasswordMobileURI(code)}>
              Reset password
            </GoToTellyApp>
          )}
        </Container>
        <RightBackground />
      </Main>
      <Footer />
    </Wrapper>
  )
}
