import './App.css'
import { Routes, Route } from 'react-router-dom'
import { RecoilRoot } from 'recoil'
import { ToastContainer } from 'react-toastify'

import 'i18n'
import { ActivateAccount } from 'screens/ActivateAccount'
import { ConfirmEmailUpdate } from 'screens/ConfirmEmailUpdate'
import { ResetPassword } from 'screens/ResetPassword'
import { MobileApp } from 'screens/MobileApp'
import { routes } from 'constants/routes'
import { ThemeProvider } from 'components/ThemeProvider'
import { DeleteAccount } from 'screens/DeleteAccount'
import { OpenChat } from 'screens/OpenChat'
import { Support } from 'screens/Support'
import { OpenSupportCenter } from 'screens/OpenSupportCenter'

function App(): JSX.Element {
  return (
    <RecoilRoot>
      <ThemeProvider>
        <div className="App">
          <ToastContainer />
          <Routes>
            <Route
              path={routes.activateAccount.path}
              element={<ActivateAccount />}
            />
            <Route path={routes.mobileApp.path} element={<MobileApp />} />
            <Route
              path={routes.confirmEmailUpdate.path}
              element={<ConfirmEmailUpdate />}
            />
            <Route
              path={routes.resetPassword.path}
              element={<ResetPassword />}
            />
            <Route
              path={routes.deleteAccount.path}
              element={<DeleteAccount />}
            />
            <Route path={routes.openChat.path} element={<OpenChat />} />
            <Route path={routes.support.path} element={<Support />} />
            <Route
              path={routes.supportCenter.path}
              element={<OpenSupportCenter />}
            />
          </Routes>
        </div>
      </ThemeProvider>
    </RecoilRoot>
  )
}

export default App
