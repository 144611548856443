// Grayscales
export const gray05 = '#E7E6E8'
export const gray60 = '#787878'
export const gray80 = '#313131'
export const gray100 = '#181818'
export const white = '#FFFFFF'
export const black = '#000000'
// Primary
export const primary100 = '#5D5FEF'
export const primary05 = '#CECFFA'

export const darkBackground = '#161616'
export const link = '#bac0c2'
