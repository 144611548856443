import { createGlobalStyle } from 'styled-components'

import { primaryFontFamily } from './fonts'

export const GlobalStyles = createGlobalStyle`
  body {
    background: ${({ theme }): string => theme.background};
    color: ${({ theme }): string => theme.textPrimaryColor};
    font-family: ${primaryFontFamily};
    transition: background 0.2s ease-in, color 0.2s ease-in;
  }

  h1, h2, h3 {
    color: ${({ theme }): string => theme.headerTextColor};
  }
`
