import { secondaryFontFamily } from '../../styles/fonts'
import { black, darkBackground, link } from 'styles/colors'
import styled from 'styled-components'

export const Wrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
export const Header = styled.div`
  width: 100%;
  height: 88px;
  background-color: ${darkBackground};

  @media only screen and (min-width: 992px) {
    height: 120px;
  }
`
export const LogoContainer = styled.div`
  width: 120px;
  height: 88px;
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 12px;
  margin: 0 auto;
  @media only screen and (min-width: 992px) {
    padding-top: 40px;
    margin-left: 153px;
    height: 120px;
    padding-bottom: 17px;
  }
`

export const Footer = styled.div`
  padding-top: 16px;
  padding-left: 21px;
  box-sizing: border-box;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: ${darkBackground};
  height: 166px;
  @media only screen and (min-width: 992px) {
    height: 112px;
    padding-left: 156px;
    flex-direction: row;
  }
`

export const Title = styled.h4`
  font-family: ${secondaryFontFamily};
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 170%;
  text-align: left;
  letter-spacing: 0.8px;
  text-transform: uppercase;
  margin: unset;
  color: ${darkBackground};
`

export const Message = styled.p`
  margin-top: 14px;
  text-align: left;
  font-family: ${secondaryFontFamily};
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 16px;
  letter-spacing: -0.25px;
  color: ${darkBackground};
  width: 100%;

  @media only screen and (min-width: 992px) {
    width: 648px;
  }
`

export const Main = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  color: ${darkBackground};
  background: white;
`
export const Container = styled.div`
  box-sizing: border-box;
  padding: 30px 20px 0 20px;
  width: 100%;
  height: 100%;
  background-color: white;
  @media only screen and (min-width: 992px) {
    max-width: 828px;
    padding-left: 156px;
    padding-top: 64px;
  }
`

export const Text = styled.p`
  text-align: left;
  font-family: ${secondaryFontFamily};
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 170%;
  letter-spacing: -0.2px;
  color: ${black};

  @media only screen and (min-width: 992px) {
    width: 648px;
  }
`

export const RightBackground = styled.div`
  display: none;

  @media only screen and (min-width: 1440px) {
    display: block;
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-left: 10px;
    overflow: hidden;
    &:after {
      content: '';
      position: absolute;
      background-color: ${link};
      padding-bottom: 180%;
      width: 100%;
      bottom: 0;
      left: 0;
      transform: rotate(20deg);
      transform-origin: left bottom;
    }
  }
`

export const GoToTellyApp = styled.a`
  display: inline-block;
  @media only screen and (min-width: 1440px) {
    display: none;
  }
`
