import { ReactComponent as Logo } from '../../images/TellyLogo.svg'
import { useRef } from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title,
  LogoContainer,
  Main,
  Message,
  Text,
  RightBackground,
  Footer,
  GoToTellyApp,
} from '../MobileApp/MobileApp.styles'
import { getOpenSupportCenterMobileURI } from 'constants/mobile'
import { useMount } from 'react-use'

export const OpenSupportCenter: React.FC = (): JSX.Element => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  useMount(() => {
    //Trigger click programatically
    setTimeout(() => {
      if (anchorRef.current) {
        anchorRef?.current?.click()
      }
    }, 200)
  })

  return (
    <Wrapper>
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>

      <Main>
        <Container>
          <Title>almost there!</Title>
          <Message>Please open link on your mobile phone.</Message>
          <Text>Make sure Telly mobile app is installed</Text>
          <GoToTellyApp ref={anchorRef} href={getOpenSupportCenterMobileURI()}>
            Go to Telly Mobile App
          </GoToTellyApp>
        </Container>
        <RightBackground />
      </Main>
      <Footer />
    </Wrapper>
  )
}
