import { themeAtom } from 'atoms/theme'
import { ReactNode } from 'react'
import { useRecoilValue } from 'recoil'
import { ThemeProvider as SCThemeProvider } from 'styled-components'
import { ThemeProvider as MuiThemeProvider } from '@mui/material'

import { GlobalStyles } from 'styles/GlobalStyles'
import { darkTheme, lightTheme } from 'styles/themes'
import {
  darkTheme as muiDarkTheme,
  lightTheme as muiLightTheme,
} from 'styles/muiThemes'

export const ThemeProvider = ({
  children,
}: {
  children: ReactNode
}): JSX.Element => {
  const themeState = useRecoilValue(themeAtom)

  return (
    <SCThemeProvider theme={themeState ? darkTheme : lightTheme}>
      <GlobalStyles />
      <MuiThemeProvider theme={themeState ? muiDarkTheme : muiLightTheme}>
        {children}
      </MuiThemeProvider>
    </SCThemeProvider>
  )
}
