import { ReactComponent as Logo } from '../../images/TellyLogo.svg'
import { useMount } from 'react-use'
import { parse } from 'query-string'
import { useState, useRef } from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title,
  LogoContainer,
  Main,
  Message,
  Text,
  RightBackground,
  Footer,
  GoToTellyApp,
} from './ConfirmEmailUpdate.styles'
import { getConfirmEmailMobileURI } from 'constants/mobile'

export const ConfirmEmailUpdate: React.FC = (): JSX.Element => {
  const [code, setCode] = useState('')
  const anchorRef = useRef<HTMLAnchorElement>(null)

  useMount(() => {
    const { code } = parse(location.search)
    if (code) {
      setCode(code as string)
      //Trigger click programatically
      setTimeout(() => {
        if (anchorRef.current) {
          anchorRef?.current?.click()
        }
      }, 200)
    }
  })

  return (
    <Wrapper>
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>

      <Main>
        <Container>
          <Title>almost there!</Title>
          <Message>
            Please open verification link on your mobile phone to finish setup.
          </Message>
          <Text>
            Check your email for the verification link we sent you and make sure
            to open the link on a mobile device with Telly installed.
          </Text>
          {code && (
            <GoToTellyApp ref={anchorRef} href={getConfirmEmailMobileURI(code)}>
              Go to Telly Mobile App
            </GoToTellyApp>
          )}
        </Container>
        <RightBackground />
      </Main>
      <Footer />
    </Wrapper>
  )
}
