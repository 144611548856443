import i18n from 'i18next'
import Backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'

// TV1-509: Adding ?lang=es should translate the main menu to spanish.
// ?lang=en or no provided URL param should render english.
const queryParams = new URLSearchParams(window.location.search)

// Disable TS rule because this is the way that the object must be initialized
// https://react.i18next.com/latest/using-with-hooks#configure-i18next
// eslint-disable-next-line @typescript-eslint/no-floating-promises
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false,
    },
    lng: queryParams.get('lang') || undefined,
    load: 'languageOnly',
  })

export default i18n
