import { createTheme } from '@mui/material'

import { gray80, primary100, white } from './colors'
import { primaryFontFamily } from './fonts'

export const lightTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: white,
          backgroundColor: primary100,
          fontWeight: 600,
        },
      },
    },
  },
  typography: {
    fontFamily: primaryFontFamily,
  },
})

export const darkTheme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          color: white,
          backgroundColor: primary100,
          fontWeight: 600,
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          color: white,
          backgroundColor: gray80,
          transition: 'background 0.2s ease-in, color 0.2s ease-in',
        },
      },
    },
  },
  typography: {
    fontFamily: primaryFontFamily,
  },
})
