import { ReactComponent as Logo } from '../../images/TellyLogo.svg'
import { useRef } from 'react'
import {
  Container,
  Wrapper,
  Header,
  Title,
  LogoContainer,
  Main,
  Message,
  Text,
  RightBackground,
  Footer,
  GoToTellyApp,
} from './MobileApp.styles'
import { getMobileAppURI } from 'constants/mobile'

export const MobileApp: React.FC = (): JSX.Element => {
  const anchorRef = useRef<HTMLAnchorElement>(null)

  return (
    <Wrapper>
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
      </Header>

      <Main>
        <Container>
          <Title>almost there!</Title>
          <Message>
            Please open link on your mobile phone to finish setup.
          </Message>
          <Text>Make sure Telly mobile app is installed</Text>
          <GoToTellyApp ref={anchorRef} href={getMobileAppURI()}>
            Go to Telly Mobile App
          </GoToTellyApp>
        </Container>
        <RightBackground />
      </Main>
      <Footer />
    </Wrapper>
  )
}
